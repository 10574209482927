const useFilter = () => {
  const deliveryOptions = ['home','onsite','virtual']
  const sortyByOptions = ['distance','price','-price']
  const countryOptions = useState('countryOptions', () => [])
  const searchFilterReady = useState('searchFilterReady', () => false)
  const searchFilter = useState('searchFilter', () => ({
    delivery: [...deliveryOptions],
    order: 'distance',
    country: 0,
  }))
  const filterDrawer = useState('filterDrawer', () => false)
  const filterQuery = computed(() => {
    let query = (searchFilter.value.delivery.length > 0 && searchFilter.value.delivery.length < 3)
      ? `?delivery=${searchFilter.value.delivery.join(',')}`
      : '?delivery=virtual,home,onsite'
    query += `&order=${searchFilter.value.order}`
    if (searchFilter.value.country) query += `&country=${searchFilter.value.country}`
    return query
  })
  const canReset = computed(() =>
    searchFilter.value.order !== 'distance' ||
    searchFilter.value.country !== 0 ||
    JSON.stringify(searchFilter.value.delivery.sort()) !== JSON.stringify(deliveryOptions.sort())
  )

  function resetFilters() {
    searchFilter.value.delivery = [...deliveryOptions]
    searchFilter.value.order = 'distance'
    searchFilter.value.country = 0
  }

  function setFilters() {
    const { currentRoute } = useRouter()
    if (currentRoute.value.query?.delivery) {
      const deliveryQuery = currentRoute.value.query.delivery.split(',')
      searchFilter.value.delivery = deliveryQuery.filter((item) => deliveryOptions.includes(item))
    } else {
      searchFilter.value.delivery = [...deliveryOptions]
    }
    searchFilter.value.order = currentRoute.value.query.order && sortyByOptions.includes(currentRoute.value.query.order)
      ? currentRoute.value.query.order
      : 'distance'
      searchFilter.value.country = currentRoute.value.query.country ? parseInt(currentRoute.value.query.country) : 0
    searchFilterReady.value = true
  }

  return {
    countryOptions,
    searchFilterReady,
    searchFilter,
    filterDrawer,
    filterQuery,
    canReset,
    setFilters,
    resetFilters,
  }
}

export default useFilter